










































































































































































































import { Component, Vue } from 'vue-property-decorator';
import FlagshipGoalsWizardAdjustGoalsViewModel from '@/vue-app/view-models/components/flagship/flagship-goals/flagship-goals-wizard-adjust-goals-view-model';

@Component({ name: 'FlagshipGoalsWizardAdjustGoals' })
export default class FlagshipGoalsWizardAdjustGoals extends Vue {
  flagship_goals_wizard_adjust_goals_view_model = Vue.observable(
    new FlagshipGoalsWizardAdjustGoalsViewModel(this),
  );

  created() {
    this.flagship_goals_wizard_adjust_goals_view_model.initialize();
  }
}
