import Vue from 'vue';
import TYPES from '@/types';

// Application
import GetFinalInvestmentDateCalculatorQuery
  from '@/modules/flagship/investor-goal/final-investment-date-calculator/application/queries/get-final-investment-date-calculator-query';
import GetEmergencyFundInvestorGoalCalculatorQuery
  from '@/modules/flagship/emergency-fund-investor-goal-calculator/application/queries/get-emergency-fund-investor-goal-calculator-query';
import GetCustomInvestorGoalCalculatorQuery
  from '@/modules/flagship/custom-investor-goal-calculator/application/queries/get-custom-investor-goal-calculator-query';
import GetRetirementInvestorGoalCalculatorQuery
  from '@/modules/flagship/retirement-investor-goal-calculator/application/queries/get-retirement-investor-goal-calculator-query';
import UpdateEmergencyFundInvestorGoalCommand from '@/modules/flagship/emergency-fund-investor-goal/application/command/update-emergency-fund-investor-goal-command';
import UpdateCustomInvestorGoalCommand from '@/modules/flagship/custom-investor-goal/application/command/update-custom-investor-goal-command';
import RetirementInvestorGoalCommand from '@/modules/flagship/retirement-investor-goal/application/commands/retirement-investor-goal-command';
import GetInvestorProfileFlagshipProfilingQuery from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import UpdateInvestorProfileCommand from '@/modules/flagship/investor-profile/investor-profile/application/commands/update-investor-profile-command';
import CalculateOverallInvestmentAmountQuery from '@/modules/flagship/overall-investment-amount-calculation/application/queries/calculate-overall-investment-amount-query';
import GetInvestmentProductsQuery
  from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';

// Domain
import { InvestorProfileDto } from '@/modules/flagship/investor-profile/investor-profile/domain/dtos/investor-profile-dto';
import { InvestorGoalDatesDto } from '@/modules/flagship/investor-goal/investor-goal-dates/domain/dtos/investor-goal-dates-dto';
import {
  EmergencyFundInvestorGoalDto,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/dtos/emergency-fund-investor-goal-dto';
import {
  CustomInvestorGoalDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-dto';
import {
  RetirementInvestorGoalEntity,
} from '@/modules/flagship/retirement-investor-goal/domain/entities/retirement-investor-goal-entity';
import {
  EmergencyFundInvestorGoalCalculatorDto,
} from '@/modules/flagship/emergency-fund-investor-goal-calculator/domain/dtos/emergency-fund-investor-goal-calculator-dto';
import {
  CustomInvestorGoalCalculatorDto,
} from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-investor-goal-calculator-dto';
import {
  RetirementInvestorGoalCalculatorDto,
} from '@/modules/flagship/retirement-investor-goal-calculator/domain/dtos/retirement-investor-goal-calculator-dto';
import {
  CreateEmergencyFundInvestorGoalStateManager,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/state/create-emergency-fund-investor-goal-state-manager';
import {
  FinalInvestmentDateCalculatorDto,
} from '@/modules/flagship/investor-goal/final-investment-date-calculator/domain/dtos/final-investment-date-calculator-dto';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { minValueRule, requiredRule } from '@/vue-app/utils/form-rules';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';

export default class FlagshipGoalsWizardAdjustGoalsViewModel {
  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileFlagshipProfilingQuery

  @Inject(TYPES.GET_EMERGENCY_FUND_INVESTOR_GOAL_CALCULATOR_QUERY)
  private readonly calculate_emergency_fund_fixed_time_query!:
    GetEmergencyFundInvestorGoalCalculatorQuery;

  @Inject(TYPES.GET_CUSTOM_INVESTOR_GOAL_CALCULATOR_QUERY)
  private readonly calculate_custom_goal_fixed_time_query!:
    GetCustomInvestorGoalCalculatorQuery;

  @Inject(TYPES.GET_RETIREMENT_INVESTOR_GOAL_CALCULATOR_QUERY)
  private readonly calculate_retirement_goal_monthly_pension_query!:
    GetRetirementInvestorGoalCalculatorQuery;

  @Inject(TYPES.GET_FINAL_INVESTMENT_DATE_CALCULATOR_QUERY)
  private readonly get_final_investment_date_query!: GetFinalInvestmentDateCalculatorQuery;

  @Inject(TYPES.UPDATE_EMERGENCY_FUND_INVESTOR_GOAL_COMMAND)
  private readonly update_emergency_fund_command!: UpdateEmergencyFundInvestorGoalCommand;

  @Inject(TYPES.UPDATE_CUSTOM_INVESTOR_GOAL_COMMAND)
  private readonly update_custom_investor_goal_command!: UpdateCustomInvestorGoalCommand;

  @Inject(TYPES.RETIREMENT_INVESTOR_GOAL_COMMAND)
  private readonly update_retirement_investor_goal_command!: RetirementInvestorGoalCommand;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_COMMAND)
  private readonly update_investor_profile_command!: UpdateInvestorProfileCommand;

  @Inject(TYPES.CALCULATE_OVERALL_INVESTMENT_AMOUNT_QUERY)
  private readonly calculate_overall_investment_amount_query!:
    CalculateOverallInvestmentAmountQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.CREATE_EMERGENCY_FUND_INVESTOR_GOAL_STATE_MANAGER)
  private readonly emergency_fund_investor_goal_state!: CreateEmergencyFundInvestorGoalStateManager;

  @Inject(TYPES.DATETIME_VALUE)
  readonly datetime_value!: DatetimeValue;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  i18n_namespace = 'components.flagship.flagship-goals.flagship_goals_wizard_adjust_goals';

  emergency_fund_slider_max_value = 25000;

  emergency_fund_slider_min_value = 100;

  custom_goal_fund_slider_max_value = 50000;

  custom_goal_fund_slider_min_value = 100;

  retirement_goal_slider_max_value = 25000;

  monthly_required_amount = {
    emergency_fund_investor_goal_monthly_required_amount: 0,
    custom_investor_goal_monthly_required_amount: 0,
    retirement_investor_goal_monthly_required_amount: 0,
  };

  emergency_fund_monthly_payment = 0;

  emergency_fund_monthly_payment_field = 0;

  customized_goal_monthly_payment = 0;

  customized_goal_monthly_payment_field = 0;

  custom_goal_icon = '';

  retirement_goal_monthly_payment = 0;

  retirement_goal_monthly_payment_field = 0;

  retirement_goal_monthly_pension = 0;

  retirement_range_adjusted = 0;

  overall_investment_amount = 0;

  minimum_monthly_amount_required_retirement_goal = 0;

  investment_product_wealth_id = '';

  investment_product_pocket_id = '';

  emergency_fund_investor_goal_dto: EmergencyFundInvestorGoalDto = {
    id: '',
    fixed_time_adjusted: '0',
    investor_goal: {
      investor_profile_id: '',
      accumulated_amount: 0,
      initial_amount: 0,
      monthly_required_amount: 0,
    },
  };

  custom_investor_goal_dto: CustomInvestorGoalDto = {
    id: '',
    fixed_time_adjusted: '0',
    goal_name: '',
    investor_goal: {
      investor_profile_id: '',
      accumulated_amount: 0,
      initial_amount: 0,
      monthly_required_amount: 0,
    },
  };

  retirement_investor_goal_dto: RetirementInvestorGoalEntity = {
    id: '',
    monthly_pension_adjusted: '0',
    issued_age: 0,
    retirement_age_adjusted: 0,
    retirement_range_adjusted: '',
    pension_range_adjusted: 0,
    investor_goal: {
      investor_profile_id: '',
      accumulated_amount: 0,
      initial_amount: 0,
      monthly_required_amount: '',
    },
  };

  private investor_profile_dto: InvestorProfileDto = {
    id: '',
    goals_completed: false,
  };

  private investor_goal_dates_dto_emergency: InvestorGoalDatesDto = {
    investor_goal_id: '',
    investment_goal_type_id: '',
    period: 0,
  }

  private investor_goal_dates_dto_retirement: InvestorGoalDatesDto = {
    investor_goal_id: '',
    investment_goal_type_id: '',
    period: 0,
  }

  private investor_goal_dates_dto_custom: InvestorGoalDatesDto = {
    investor_goal_id: '',
    investment_goal_type_id: '',
    period: 0,
  }

  is_valid_form = false;

  input_rules = {
    emergency_fund_monthly_payment_field: [requiredRule, (value: string) => minValueRule(value.toString().replace(/[^0-9.-]/g, ''), '$1.00 MXN')],
    customized_goal_monthly_payment_field: [requiredRule, (value: string) => minValueRule(value.toString().replace(/[^0-9.-]/g, ''), '$1.00 MXN')],
    retirement_goal_monthly_payment_field: [requiredRule, (value: string) => minValueRule(
      value.toString().replace(/[^0-9.-]/g, ''),
      `${this.minimum_monthly_amount_required_retirement_goal}.00 MXN`,
      this.minimum_monthly_amount_required_retirement_goal,
    )],
  };

  is_loading = false;

  timer?: NodeJS.Timer;

  investor_goal_state = this.emergency_fund_investor_goal_state.state;

  readonly view!: Vue;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get emergency_fund_fixed_time_rounded() {
    return Math.round(Number(this.emergency_fund_investor_goal_dto.fixed_time_adjusted));
  }

  get customized_goal_fixed_time_rounded() {
    return Math.round(Number(this.custom_investor_goal_dto.fixed_time_adjusted));
  }

  get overall_investment_amount_formatted() {
    return Math.trunc(this.overall_investment_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  get monthly_pension_formatted() {
    return Math.trunc(this.retirement_goal_monthly_pension).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  get is_continue_btn_disabled() {
    return !this.is_valid_form || this.is_loading;
  }

  loadInvestmentProducts = async () => {
    try {
      const investment_products = await this.get_investment_products_query.execute();
      investment_products.forEach((product) => {
        if (product.name === 'sowos_wealth') {
          this.investment_product_wealth_id = product.id;
        }
        if (product.name === 'sowos_pocket') {
          this.investment_product_pocket_id = product.id;
        }
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investment_products_query'));
    }
  }

  getInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();
      this.investor_profile_dto.id = investor_profile.id;
      this.emergency_fund_investor_goal_dto.investor_goal.investor_profile_id = investor_profile.id;
      this.custom_investor_goal_dto.investor_goal.investor_profile_id = investor_profile.id;
      this.retirement_investor_goal_dto.investor_goal.investor_profile_id = investor_profile.id;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investor_profile'));
    }
  };

  getEmergencyFundGoal = async () => {
    const emergency_fund_goal = this.investor_goal_state.emergency;
    this.emergency_fund_investor_goal_dto.id = emergency_fund_goal.id;
    this.emergency_fund_investor_goal_dto.desired_amount = emergency_fund_goal.desired_amount;
    this.emergency_fund_investor_goal_dto.fixed_time_adjusted = emergency_fund_goal
      .fixed_time_adjusted.toString();
    this.emergency_fund_investor_goal_dto.investor_goal.accumulated_amount = emergency_fund_goal
      .accumulated_amount;
    this.emergency_fund_monthly_payment = Math.trunc(emergency_fund_goal.monthly_required_amount);
    this.emergency_fund_monthly_payment_field = Math.trunc(
      emergency_fund_goal.monthly_required_amount,
    );
    this.monthly_required_amount
      .emergency_fund_investor_goal_monthly_required_amount = emergency_fund_goal
        .monthly_required_amount;
    this.investor_goal_dates_dto_emergency.investor_goal_id = emergency_fund_goal
      .id;
    this.investor_goal_dates_dto_emergency.investment_goal_type_id = emergency_fund_goal
      .investment_goal_type_id;
    this.investor_goal_dates_dto_emergency.period = emergency_fund_goal.fixed_time_adjusted;
    this.emergency_fund_slider_max_value = (
      emergency_fund_goal.desired_amount - emergency_fund_goal.initial_amount
    );
  };

  getCustomGoal = async () => {
    const custom_goal = this.investor_goal_state.custom;
    this.custom_investor_goal_dto.id = custom_goal.id;
    this.custom_investor_goal_dto.desired_amount = custom_goal.desired_amount;
    this.custom_investor_goal_dto.fixed_time_adjusted = custom_goal.fixed_time_adjusted.toString();
    this.custom_investor_goal_dto.goal_name = custom_goal.goal_name;
    this.custom_investor_goal_dto.custom_goal_type = custom_goal.goal_type;
    this.custom_investor_goal_dto.investor_goal.accumulated_amount = custom_goal
      .accumulated_amount;
    this.customized_goal_monthly_payment = Math.trunc(custom_goal.monthly_required_amount);
    this.customized_goal_monthly_payment_field = Math.trunc(custom_goal.monthly_required_amount);
    this.monthly_required_amount.custom_investor_goal_monthly_required_amount = custom_goal
      .monthly_required_amount;
    this.custom_goal_icon = custom_goal.goal_type.icon_name;
    this.investor_goal_dates_dto_custom.investor_goal_id = custom_goal.id;
    this.investor_goal_dates_dto_custom.investment_goal_type_id = custom_goal
      .investment_goal_type_id;
    this.investor_goal_dates_dto_custom.period = custom_goal.fixed_time_adjusted;
    this.custom_goal_fund_slider_max_value = custom_goal.desired_amount;
  };

  getRetirementGoal = async () => {
    const retirement_goal = this.investor_goal_state.retirement;
    this.retirement_investor_goal_dto.id = retirement_goal.id;
    this.retirement_investor_goal_dto.issued_age = retirement_goal.issued_age;
    this.retirement_investor_goal_dto.retirement_age_adjusted = retirement_goal
      .retirement_age_adjusted;
    this.retirement_investor_goal_dto.retirement_range_adjusted = retirement_goal
      .retirement_range_adjusted.toString();
    this.retirement_investor_goal_dto.pension_range_adjusted = retirement_goal
      .pension_range_adjusted;
    this.retirement_investor_goal_dto.monthly_pension_adjusted = retirement_goal
      .monthly_pension_adjusted.toString();
    this.retirement_investor_goal_dto.investor_goal.accumulated_amount = retirement_goal
      .accumulated_amount;
    this.retirement_goal_monthly_payment = Math.trunc(retirement_goal.monthly_required_amount!);
    this.retirement_goal_monthly_payment_field = Math.trunc(
      retirement_goal.monthly_required_amount!,
    );
    this.monthly_required_amount
      .retirement_investor_goal_monthly_required_amount = retirement_goal.monthly_required_amount!;
    this.minimum_monthly_amount_required_retirement_goal = this.monthly_required_amount
      .retirement_investor_goal_monthly_required_amount < 1 ? 0 : 1;
    this.retirement_goal_monthly_pension = parseFloat(retirement_goal.monthly_pension_adjusted);
    this.investor_goal_dates_dto_retirement.investor_goal_id = retirement_goal.id;
    this.investor_goal_dates_dto_retirement.investment_goal_type_id = retirement_goal
      .investment_goal_type_id;
    this.investor_goal_dates_dto_retirement.period = parseFloat(
      retirement_goal.retirement_range_adjusted,
    );
    this.retirement_range_adjusted = parseFloat(retirement_goal.retirement_range_adjusted);
    this.retirement_goal_slider_max_value = retirement_goal.accumulated_amount;
  };

  loadFinalInvestmentDate = async (months_to_add: number): Promise<{
    final_investment_date: string;
    initial_investment_date: string;
  }> => {
    const initial_investment_date = this.datetime_value.create().toString();
    const payload: FinalInvestmentDateCalculatorDto = {
      initial_investment_date,
      period_in_months: months_to_add,
    };
    const { final_investment_date } = await this.get_final_investment_date_query.execute(payload);
    return { initial_investment_date, final_investment_date };
  }

  calculateOverallInvestmentAmount = async () => {
    try {
      // eslint-disable-next-line max-len
      const { overall_investment_amount_result } = await this.calculate_overall_investment_amount_query.execute(this.monthly_required_amount);
      this.overall_investment_amount = overall_investment_amount_result;
    } catch (e) {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_overall_investment_amount'));
    }
  };

  delayEmergencyFundField = () => {
    this.is_loading = true;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      await this.changeEmergencyFundField();
    }, 1000);
  }

  changeEmergencyFundField = async () => {
    if (this.is_valid_form) {
      const monthly_payment = (String(this.emergency_fund_monthly_payment_field) !== '$0 MXN')
        ? Number(parseFloat(String(this.emergency_fund_monthly_payment_field).replaceAll(/[^\d.-]/g, '')).toFixed(2))
        : this.emergency_fund_slider_min_value;

      const max_monthly_payment_allowed = Math.trunc(this.emergency_fund_slider_max_value);

      this.emergency_fund_monthly_payment = monthly_payment < this.emergency_fund_slider_max_value
        ? monthly_payment : max_monthly_payment_allowed;

      // eslint-disable-next-line max-len
      this.monthly_required_amount.emergency_fund_investor_goal_monthly_required_amount = monthly_payment < this.emergency_fund_slider_max_value
        ? monthly_payment : max_monthly_payment_allowed;

      this.emergency_fund_monthly_payment_field = monthly_payment
      // eslint-disable-next-line max-len
      < this.emergency_fund_slider_max_value ? monthly_payment : max_monthly_payment_allowed;
      await this.calculateEmergencyFundFixedTime();
    }
  };

  changeEmergencyFundSlider = async () => {
    // eslint-disable-next-line max-len
    this.monthly_required_amount.emergency_fund_investor_goal_monthly_required_amount = this.emergency_fund_monthly_payment;
    this.emergency_fund_monthly_payment_field = this.emergency_fund_monthly_payment;
    await this.calculateEmergencyFundFixedTime();
  };

  calculateEmergencyFundFixedTime = async () => {
    try {
      this.is_loading = true;
      const payload: EmergencyFundInvestorGoalCalculatorDto = {
        associated_product_id: this.investment_product_pocket_id,
        defined_by: 'contribution',
        desired_amount: this.investor_goal_state.emergency.desired_amount,
        initial_amount: this.investor_goal_state.emergency.initial_amount,
        monthly_required_amount: this.monthly_required_amount
          .emergency_fund_investor_goal_monthly_required_amount,
        fixed_time_adjusted: 0,
      };
      const calculation = await this
        .calculate_emergency_fund_fixed_time_query.execute(payload);
      this.emergency_fund_investor_goal_dto.fixed_time_adjusted = String(
        calculation.emergency_fund_calculation.fixed_time_adjusted,
      );
      this.investor_goal_dates_dto_emergency.period = calculation
        .emergency_fund_calculation.fixed_time_adjusted;
      await this.calculateOverallInvestmentAmount();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_emergency_fund_fixed_time'));
    } finally {
      this.is_loading = false;
    }
  };

  delayCustomGoalField = () => {
    this.is_loading = true;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      await this.changeCustomGoalField();
    }, 1000);
  }

  changeCustomGoalField = async () => {
    if (this.is_valid_form) {
      const monthly_payment = (String(this.customized_goal_monthly_payment_field) !== '$0 MXN')
        ? Number(parseFloat(String(this.customized_goal_monthly_payment_field).replaceAll(/[^\d.-]/g, '')).toFixed(2))
        : this.custom_goal_fund_slider_min_value;

      const max_monthly_payment_allowed = Math.trunc(this.custom_goal_fund_slider_max_value);
      // eslint-disable-next-line max-len
      this.customized_goal_monthly_payment = monthly_payment < this.custom_goal_fund_slider_max_value
        ? monthly_payment : max_monthly_payment_allowed;

      this.monthly_required_amount.custom_investor_goal_monthly_required_amount = monthly_payment
      < this.custom_goal_fund_slider_max_value
        ? monthly_payment : max_monthly_payment_allowed;

      this.customized_goal_monthly_payment_field = monthly_payment
      < this.custom_goal_fund_slider_max_value ? monthly_payment
        : max_monthly_payment_allowed;
      await this.calculateCustomGoalFixedTime();
    }
  };

  changeCustomGoalSlider = async () => {
    // eslint-disable-next-line max-len
    this.monthly_required_amount.custom_investor_goal_monthly_required_amount = this.customized_goal_monthly_payment;
    this.customized_goal_monthly_payment_field = this.customized_goal_monthly_payment;
    await this.calculateCustomGoalFixedTime();
  };

  calculateCustomGoalFixedTime = async () => {
    try {
      this.is_loading = true;
      const investment_product_id = this.investor_goal_state.custom.fixed_time_adjusted > 11
        ? this.investment_product_wealth_id : this.investment_product_pocket_id;
      const payload: CustomInvestorGoalCalculatorDto = {
        associated_product_id: investment_product_id,
        defined_by: 'contribution',
        desired_amount: this.investor_goal_state.custom.desired_amount,
        initial_amount: this.investor_goal_state.custom.initial_amount,
        monthly_required_amount: this.monthly_required_amount
          .custom_investor_goal_monthly_required_amount,
        fixed_time_adjusted: 0,
        set_associated_product: true,
      };
      const calculation = await this
        .calculate_custom_goal_fixed_time_query.internalExecute(payload);
      this.custom_investor_goal_dto.fixed_time_adjusted = String(calculation
        .custom_investor_goal_calculation.fixed_time_adjusted);
      this.investor_goal_dates_dto_custom.period = calculation
        .custom_investor_goal_calculation.fixed_time_adjusted;
      await this.calculateOverallInvestmentAmount();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_custom_goal_fixed_time'));
    } finally {
      this.is_loading = false;
    }
  };

  delayRetirementGoalField = () => {
    this.is_loading = true;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      await this.changeRetirementGoalField();
    }, 1000);
  }

  changeRetirementGoalField = async () => {
    if (this.is_valid_form) {
      const monthly_payment = Number(parseFloat(String(this.retirement_goal_monthly_payment_field).replaceAll(/[^\d.-]/g, '')).toFixed(2));

      const max_monthly_payment_allowed = Math.trunc(this.retirement_goal_slider_max_value);

      this.retirement_goal_monthly_payment = monthly_payment < this.retirement_goal_slider_max_value
        ? monthly_payment : max_monthly_payment_allowed;
      // eslint-disable-next-line max-len
      this.monthly_required_amount.retirement_investor_goal_monthly_required_amount = monthly_payment
      < this.retirement_goal_slider_max_value
        ? monthly_payment : max_monthly_payment_allowed;

      this.retirement_goal_monthly_payment_field = monthly_payment
      < this.retirement_goal_slider_max_value ? monthly_payment
        : max_monthly_payment_allowed;
      await this.calculateRetirementGoalMonthlyPensionAdjusted();
    }
  };

  changeRetirementGoalSlider = async () => {
    // eslint-disable-next-line max-len
    this.monthly_required_amount.retirement_investor_goal_monthly_required_amount = this.retirement_goal_monthly_payment;
    this.retirement_goal_monthly_payment_field = this.retirement_goal_monthly_payment;
    await this.calculateRetirementGoalMonthlyPensionAdjusted();
  };

  calculateRetirementGoalMonthlyPensionAdjusted = async () => {
    try {
      this.is_loading = true;
      const payload: RetirementInvestorGoalCalculatorDto = {
        associated_product_id: this.investment_product_wealth_id,
        defined_by: 'contribution',
        issued_age: this.investor_goal_state.retirement.issued_age,
        retirement_age_adjusted: this.investor_goal_state.retirement.retirement_age_adjusted,
        initial_amount: this.investor_goal_state.retirement.initial_amount_adjusted,
        monthly_required_amount: this.monthly_required_amount
          .retirement_investor_goal_monthly_required_amount,
        monthly_pension_adjusted: 0,
      };
      const calculation = await this
        .calculate_retirement_goal_monthly_pension_query.internalExecute(payload);
      this.retirement_goal_monthly_pension = calculation
        .retirement_calculation_data.monthly_pension_adjusted;
      this.retirement_investor_goal_dto.investor_goal.accumulated_amount = calculation
        .retirement_calculation_data.accumulated_amount;
      await this.calculateOverallInvestmentAmount();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_retirement_monthly_pension'));
    } finally {
      this.is_loading = false;
    }
  };

  patchEmergencyFundGoal = async () => {
    try {
      const { initial_investment_date, final_investment_date } = await this.loadFinalInvestmentDate(
        this.investor_goal_state.emergency.fixed_time_adjusted,
      );
      this.emergency_fund_investor_goal_dto.investor_goal
        .initial_investment_date = initial_investment_date;
      this.emergency_fund_investor_goal_dto.investor_goal
        .final_investment_date = final_investment_date;
      this.emergency_fund_investor_goal_dto.investor_goal.monthly_required_amount = this
        .monthly_required_amount.emergency_fund_investor_goal_monthly_required_amount;
      this.emergency_fund_investor_goal_dto.investor_goal.initial_amount = this.investor_goal_state
        .emergency.initial_amount;
      await this.update_emergency_fund_command.execute(this.emergency_fund_investor_goal_dto);
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_emergency_fund_investor_goal'));
      return false;
    }
  };

  patchCustomInvestorGoal = async () => {
    try {
      const { initial_investment_date, final_investment_date } = await this.loadFinalInvestmentDate(
        this.investor_goal_state.custom.fixed_time_adjusted,
      );
      this.custom_investor_goal_dto.investor_goal
        .initial_investment_date = initial_investment_date;
      this.custom_investor_goal_dto.investor_goal
        .final_investment_date = final_investment_date;
      this.custom_investor_goal_dto.investor_goal.monthly_required_amount = this
        .monthly_required_amount.custom_investor_goal_monthly_required_amount;
      this.custom_investor_goal_dto.investor_goal.initial_amount = this.investor_goal_state
        .custom.initial_amount;
      await this.update_custom_investor_goal_command.execute(this.custom_investor_goal_dto);
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_custom_investor_goal'));
      return false;
    }
  }

  patchRetirementInvestorGoal = async () => {
    try {
      const { initial_investment_date, final_investment_date } = await this.loadFinalInvestmentDate(
        parseFloat(this.investor_goal_state.retirement.retirement_range_adjusted),
      );
      this.retirement_investor_goal_dto.investor_goal
        .initial_investment_date = initial_investment_date;
      this.retirement_investor_goal_dto.investor_goal
        .final_investment_date = final_investment_date;
      this.retirement_investor_goal_dto.monthly_pension_adjusted = this
        .retirement_goal_monthly_pension.toFixed(2);
      this.retirement_investor_goal_dto.investor_goal.monthly_required_amount = this
        .monthly_required_amount.retirement_investor_goal_monthly_required_amount.toFixed(2);
      this.retirement_investor_goal_dto.investor_goal.initial_amount = this.investor_goal_state
        .retirement.initial_amount;
      this.retirement_investor_goal_dto.investor_goal.initial_amount_adjusted = this
        .investor_goal_state.retirement.initial_amount_adjusted;
      await this.update_retirement_investor_goal_command.execute(this.retirement_investor_goal_dto);
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_custom_investor_goal'));
      return false;
    }
  }

  updateInvestorProfile = async () => {
    try {
      this.investor_profile_dto.goals_completed = true;
      await this.update_investor_profile_command.execute(this.investor_profile_dto);
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_investor_profile'));
      return false;
    }
  }

  calculateEmergencyMaximumMonthlyAmount = async () => {
    try {
      this.is_loading = true;
      const payload: EmergencyFundInvestorGoalCalculatorDto = {
        associated_product_id: this.investment_product_pocket_id,
        defined_by: 'time',
        desired_amount: this.investor_goal_state.emergency.desired_amount,
        initial_amount: this.investor_goal_state.emergency.initial_amount,
        monthly_required_amount: 0,
        fixed_time_adjusted: 1,
      };
      const calculated_data = await this
        .calculate_emergency_fund_fixed_time_query.execute(payload);
      this.emergency_fund_slider_max_value = calculated_data
        .emergency_fund_calculation.monthly_required_amount;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_monthly_payment'));
    } finally {
      this.is_loading = true;
    }
  }

  calculateCustomMaximumMonthlyAmount = async () => {
    try {
      const investment_product_id = this.investor_goal_state.custom.fixed_time_adjusted > 11
        ? this.investment_product_wealth_id : this.investment_product_pocket_id;
      this.is_loading = true;
      const payload: CustomInvestorGoalCalculatorDto = {
        associated_product_id: investment_product_id,
        defined_by: 'time',
        desired_amount: this.investor_goal_state.custom.desired_amount,
        initial_amount: this.investor_goal_state.custom.initial_amount,
        monthly_required_amount: 0,
        fixed_time_adjusted: 1,
      };
      const calculated_data = await this
        .calculate_custom_goal_fixed_time_query.execute(payload);
      this.custom_goal_fund_slider_max_value = calculated_data
        .custom_investor_goal_calculation.monthly_required_amount;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_monthly_payment'));
    } finally {
      this.is_loading = false;
    }
  }

  initialize = async () => {
    this.is_loading = true;
    await this.loadInvestmentProducts();
    await this.getInvestorProfile();
    await this.getEmergencyFundGoal();
    await this.getCustomGoal();
    await this.getRetirementGoal();
    await this.calculateOverallInvestmentAmount();
    await this.calculateCustomMaximumMonthlyAmount();
    await this.calculateEmergencyMaximumMonthlyAmount();
    this.is_loading = false;
  };

  onSubmit = async () => {
    this.is_loading = true;
    const stored_emergency = await this.patchEmergencyFundGoal();
    const stored_custom = await this.patchCustomInvestorGoal();
    const stored_retirement = await this.patchRetirementInvestorGoal();
    const stored_investor_profile = await this.updateInvestorProfile();
    this.is_loading = false;
    if (stored_emergency && stored_custom
      && stored_retirement && stored_investor_profile) {
      this.view.$emit('nextStep');
    }
  };
}
